import { useWeb3Modal, useWeb3ModalState } from '@web3modal/wagmi/react';
import React, { useRef } from 'react'
import { useAccount, useDisconnect, useChainId, useSwitchNetwork, useNetwork } from 'wagmi'
import config from 'configs/app'
import * as mixpanel from 'lib/mixpanel/index'
import { logWalletConnection, logNetworkAdd } from './../../analytics/logEvents'

export default function useWallet() {
  const { open } = useWeb3Modal();
  const { open: isOpen } = useWeb3ModalState();
  const { disconnect } = useDisconnect()
  const [isModalOpening, setIsModalOpening] = React.useState(false)
  const [isClientLoaded, setIsClientLoaded] = React.useState(false)
  const refIsConnecting = useRef(false)
  const { chain: currentProviderChain } = useNetwork()
  const { switchNetworkAsync } = useSwitchNetwork({ chainId: Number(config?.chain?.id ?? 0) })
  React.useEffect(() => {
    setIsClientLoaded(true)
  }, [])

  const handleConnect = React.useCallback(async () => {
    setIsModalOpening(true)
    refIsConnecting.current = true
    await open()
    setIsModalOpening(false)
    mixpanel.logEvent(mixpanel.EventTypes.WALLET_CONNECT, { Source: 'Header', Status: 'Started' })
  }, [open])

  const handleAccountConnected = React.useCallback(
    ({ isReconnected }: { isReconnected: boolean }) => {
      !isReconnected &&
        mixpanel.logEvent(mixpanel.EventTypes.WALLET_CONNECT, {
          Source: 'Header',
          Status: 'Connected'
        })
    },
    []
  )

  const handleDisconnect = React.useCallback(() => {
    disconnect()
  }, [disconnect])

  const { address, isDisconnected } = useAccount({ onConnect: handleAccountConnected })

  React.useEffect(() => {
    try {
      if (address && refIsConnecting.current == true) {
        logWalletConnection(address)
        refIsConnecting.current = false
        if (currentProviderChain?.id == Number(config?.chain?.id ?? 0)) {
          logNetworkAdd(address)
        } else {
          switchNetworkAsync?.()
            ?.then((res) => {
              logNetworkAdd(address)
            })
            .catch((ex) => {})
        }
      }
    } catch (error) {}
  }, [
    address,
    refIsConnecting.current,
    currentProviderChain?.id,
    config?.chain?.id,
    switchNetworkAsync
  ])

  const isWalletConnected = isClientLoaded && !isDisconnected && address !== undefined

  return {
    isWalletConnected,
    address: address || '',
    connect: handleConnect,
    disconnect: handleDisconnect,
    isModalOpening,
    isModalOpen: isOpen
  }
}
