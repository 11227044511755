import axios from 'axios'
import { getEnvValue } from 'configs/app/utils'
const POST_ANALYTICS_END_POINT = getEnvValue('NEXT_PUBLIC_VANAR_ANALYTICS')
const AUTH_KEY = getEnvValue('NEXT_PUBLIC_ANALYTICS_AUTH_KEY')
export const logWalletConnection = (walletAddress) => {
  const data = {
    wallet_address: walletAddress,
    event_type: 'isExplorerWalletConnected',
    source_from: 'Explorer'
  }
  axios
    .post(POST_ANALYTICS_END_POINT, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: AUTH_KEY
      }
    })
    .then((res) => {
      // console.log('logWalletConnection Success ===> ', res)
    })
    .catch((ex) => {
      console.log('logWalletConnection Eroor ===> ', ex)
    })
}

export const logNetworkAdd = (walletAddress) => {
  try {
    const data = {
      wallet_address: walletAddress,
      event_type: 'isNetworkAdded',
      source_from: 'Explorer'
    }
    axios
      .post(POST_ANALYTICS_END_POINT, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: AUTH_KEY
        }
      })
      .then((res) => {
        // console.log('isNetworkAdded Success ===> ', res)
      })
      .catch((ex) => {
        console.log('isNetworkAdded Eroor ===> ', ex)
      })
  } catch (error) {}
}
